import type { ComponentRef, EditorSDK } from '@wix/editor-platform-sdk-types';
import {
  type Color,
  COLORS_CODES,
  convertStyles,
  extractBorderRadius,
  type Font,
  type FontMap,
} from './style-utils';

type ButtonStyle = {
  'alpha-bg': string | undefined;
  'alpha-bgh': string | undefined;
  'alpha-brd': string | undefined;
  'alpha-brdh': string | undefined;
  'alpha-txt': string | undefined;
  'alpha-txth': string | undefined;
  bg: string | undefined;
  bgh: string | undefined;
  'boxShadowToggleOn-shd': string | undefined;
  brd: string | undefined;
  brdh: string | undefined;
  brw: string | undefined;
  fnt: string | undefined;
  rd: string | undefined;
  shd: string | undefined;
  txt: string | undefined;
  txth: string | undefined;
  bgd: string | undefined;
  txtd: string | undefined;
};

enum ButtonFieldBorderStyle {
  Rectangle = 0,
  RectangleRoundedCorners = 1,
  RectangleFilled = 2,
  RectangleRoundedCornersFilled = 3,
  ABC = 4, // Next/Previous buttons do not have this option
}

type ButtonFieldStyle = {
  colors: {
    buttonsColor: Color;
    buttonsColorHover: Color;
    buttonsBackgroundColor: Color;
    buttonsBackgroundColorHover: Color;
    buttonsBorderColor: Color;
    submitButtonColor: Color;
    submitButtonColorHover: Color;
    submitButtonBackgroundColor: Color;
    submitButtonBackgroundColorHover: Color;
    submitButtonBorderColor: Color;
    submitButtonBorderColorHover: Color;
    previousButtonColor: Color;
    previousButtonColorHover: Color;
    previousButtonBackgroundColor: Color;
    previousButtonBackgroundColorHover: Color;
    previousButtonBorderColor: Color;
    previousButtonBorderColorHover: Color;
    nextButtonColor: Color;
    nextButtonColorHover: Color;
    nextButtonBackgroundColor: Color;
    nextButtonBackgroundColorHover: Color;
    nextButtonBorderColor: Color;
    nextButtonBorderColorHover: Color;
  };
  fonts: {
    submitButtonFont: Font;
    previousButtonFont: Font;
    nextButtonFont: Font;
  };
  numbers: {
    buttonsStyle: number | undefined;
    buttonsBorderWidth: number | undefined;
    buttonsBorderRadius: number | undefined;
    submitButtonStyle: number | undefined;
    submitButtonBorderWidth: number | undefined;
    submitButtonBorderRadius: number | undefined;
    previousButtonStyle: number | undefined;
    previousButtonBorderWidth: number | undefined;
    previousButtonBorderRadius: number | undefined;
    nextButtonStyle: number | undefined;
    nextButtonBorderWidth: number | undefined;
    nextButtonBorderRadius: number | undefined;
  };
};

const TOKEN = 'TOKEN';

function debug(...args: unknown[]) {
  console.log('migration >', ...args);
}

export async function getButtonStyle(sdk: EditorSDK, buttonRef: ComponentRef) {
  const TAG = `buttonRef: ${buttonRef.id} >`;
  const buttonStyle = await sdk.components.style.get(TOKEN, {
    componentRef: buttonRef,
  });

  if (!buttonStyle) {
    debug(TAG, 'button style not found');
  }

  debug(TAG, 'resolved button style', buttonStyle);

  const fontMap: FontMap = await sdk.theme.fonts.getMap(TOKEN);

  const properties: ButtonStyle = buttonStyle.style.properties;
  const propertiesSource: ButtonStyle = buttonStyle.style.propertiesSource;

  const toColor = (
    name: keyof ButtonStyle,
    alphaOverride?: number,
    fallback?: string,
  ): Color => {
    const value = properties[name];
    const valueSource = propertiesSource[name] ?? 'value';

    const alphaName = `alpha-${name}` as keyof ButtonStyle;
    const alpha = parseFloat(properties[alphaName] ?? '1');
    const alphaSource = propertiesSource[alphaName] ?? 'value';

    return value
      ? { value, valueSource, alpha: alphaOverride ?? alpha, alphaSource }
      : fallback
      ? {
          value: fallback,
          valueSource: 'value',
          alpha: alphaOverride ?? alpha,
          alphaSource: 'value',
        }
      : undefined;
  };

  const toFont = (name: keyof ButtonStyle): Font => {
    const value = properties[name];
    const valueSource = propertiesSource[name] ?? 'value';

    return value && valueSource ? { value, valueSource } : undefined;
  };

  const getIsTextButton = (hover?: boolean) => {
    const hasBorder =
      properties.brw &&
      parseInt(properties.brw, 10) &&
      toColor(hover ? 'brdh' : 'brd');
    if (hasBorder) {
      return false;
    }

    const backgroundColor = toColor(hover ? 'bgh' : 'bg');
    const hasBackground = backgroundColor && backgroundColor.alpha > 0;
    if (hasBackground) {
      return false;
    }

    return true;
  };

  const getIsBorderButton = (hover?: boolean) => {
    const backgroundColor = toColor(hover ? 'bgh' : 'bg');
    const hasBackground = backgroundColor && backgroundColor.alpha > 0;
    if (hasBackground) {
      return false;
    }

    const hasBorder =
      properties.brw &&
      parseInt(properties.brw, 10) > 0 &&
      toColor(hover ? 'brdh' : 'brd');
    if (hasBorder) {
      return true;
    }

    return false;
  };

  const isTextButton = getIsTextButton();
  const isTextButtonHover = getIsTextButton(true);
  const isBorderButton = getIsBorderButton();
  const isBorderButtonOnHover = getIsBorderButton(true);

  const styleMap: ButtonFieldStyle = {
    colors: {
      buttonsColor: toColor('txt'),
      buttonsColorHover: toColor('txth'),
      buttonsBackgroundColor: isTextButton
        ? toColor('bg', 0, COLORS_CODES.WHITE)
        : toColor('bg'),
      buttonsBackgroundColorHover: isTextButtonHover
        ? toColor('bgh', 0, COLORS_CODES.WHITE)
        : toColor('bgh'),
      buttonsBorderColor: isTextButton
        ? toColor('brd', 0, COLORS_CODES.BLACK)
        : toColor('brd'),

      submitButtonColor: toColor('txt'),
      submitButtonColorHover: toColor('txth'),
      submitButtonBackgroundColor: toColor('bg'),
      submitButtonBackgroundColorHover: toColor('bgh'),
      submitButtonBorderColor: toColor('brd'),
      submitButtonBorderColorHover: toColor('brdh'),

      previousButtonColor:
        isTextButton || isBorderButton ? toColor('txt') : toColor('bg'),
      previousButtonColorHover:
        isTextButtonHover || isBorderButtonOnHover
          ? toColor('txth')
          : toColor('bgh'),
      previousButtonBackgroundColor:
        isTextButton || isBorderButton
          ? toColor('bg', 0, COLORS_CODES.WHITE)
          : toColor('txt'),
      previousButtonBackgroundColorHover:
        isTextButtonHover || isBorderButtonOnHover
          ? toColor('bgh', 0, COLORS_CODES.WHITE)
          : toColor('txth'),
      previousButtonBorderColor: isTextButton
        ? toColor('brd', 0, COLORS_CODES.BLACK)
        : toColor('brd'),
      previousButtonBorderColorHover: isTextButtonHover
        ? toColor('brdh', 0, COLORS_CODES.BLACK)
        : toColor('brdh'),

      nextButtonColor: toColor('txt'),
      nextButtonColorHover: toColor('txth'),
      nextButtonBackgroundColor: isTextButton
        ? toColor('bg', 0, COLORS_CODES.WHITE)
        : toColor('bg'),
      nextButtonBackgroundColorHover: isTextButtonHover
        ? toColor('bgh', 0, COLORS_CODES.WHITE)
        : toColor('bgh'),
      nextButtonBorderColor: isTextButton
        ? toColor('brd', 0, COLORS_CODES.BLACK)
        : toColor('brd'),
      nextButtonBorderColorHover: isTextButtonHover
        ? toColor('brdh', 0, COLORS_CODES.BLACK)
        : toColor('brdh'),
    },
    fonts: {
      submitButtonFont: toFont('fnt'),
      previousButtonFont: toFont('fnt'),
      nextButtonFont: toFont('fnt'),
    },
    numbers: {
      buttonsStyle:
        isTextButton || isBorderButton
          ? ButtonFieldBorderStyle.Rectangle
          : ButtonFieldBorderStyle.RectangleFilled,
      buttonsBorderWidth: isTextButton
        ? 0
        : properties.brw && parseInt(properties.brw, 10),
      buttonsBorderRadius: extractBorderRadius(properties.rd),

      submitButtonStyle: isTextButton
        ? ButtonFieldBorderStyle.ABC
        : isBorderButton
        ? ButtonFieldBorderStyle.Rectangle
        : ButtonFieldBorderStyle.RectangleFilled,
      submitButtonBorderWidth: properties.brw && parseInt(properties.brw, 10),
      submitButtonBorderRadius: extractBorderRadius(properties.rd),

      previousButtonStyle:
        isTextButton || isBorderButton
          ? ButtonFieldBorderStyle.Rectangle
          : ButtonFieldBorderStyle.RectangleFilled,
      previousButtonBorderWidth: isTextButton
        ? 0
        : properties.brw && parseInt(properties.brw, 10),
      previousButtonBorderRadius: extractBorderRadius(properties.rd),

      nextButtonStyle:
        isTextButton || isBorderButton
          ? ButtonFieldBorderStyle.Rectangle
          : ButtonFieldBorderStyle.RectangleFilled,
      nextButtonBorderWidth: isTextButton
        ? 0
        : properties.brw && parseInt(properties.brw, 10),
      nextButtonBorderRadius: extractBorderRadius(properties.rd),
    },
  };

  const remappedStyle = convertStyles(styleMap, fontMap);

  debug(TAG, 'remapped button style', remappedStyle);

  return remappedStyle;
}
