import type { ComponentRef, EditorSDK } from '@wix/editor-platform-sdk-types';
import {
  type BoxShadow,
  type Color,
  convertStyles,
  extractBorderRadius,
  type FontMap,
  parseBoxShadow,
} from './style-utils';

type FormContainerStyle = {
  bg: string | undefined;
  'boxShadowToggleOn-shd': string | undefined;
  rd: string | undefined;
  shd: string | undefined;
  'alpha-bg': string | undefined;
  'alpha-brd': string | undefined;
  brd: string | undefined;
  brw: string | undefined;
};

type FormStyle = {
  colors: {
    formBackground: Color;
    borderColor: Color;
    shadowColor: Color;
  };
  numbers: {
    horizontalPadding: number | undefined;
    verticalPadding: number | undefined;
    rowSpacing: number | undefined;
    columnSpacing: number | undefined;
    borderWidth: number | undefined;
    borderRadius: number | undefined;
    shadowAngle: number | undefined;
    shadowDistance: number | undefined;
    shadowSize: number | undefined;
    shadowBlur: number | undefined;
  };
  booleans: {
    enableShadow: boolean | undefined;
  };
};

const TOKEN = 'TOKEN';

function debug(...args: unknown[]) {
  console.log('migration >', ...args);
}

export async function getFormContainerStyle(
  sdk: EditorSDK,
  formRef: ComponentRef,
) {
  const TAG = `formRef: ${formRef.id} >`;
  const formStyle = await sdk.components.style.get(TOKEN, {
    componentRef: formRef,
  });

  if (!formStyle) {
    debug(TAG, 'form style not found');
  }

  debug(TAG, 'resolved form style', formStyle);

  const fontMap: FontMap = await sdk.theme.fonts.getMap(TOKEN);

  const properties: FormContainerStyle = formStyle.style.properties;
  const propertiesSource: FormContainerStyle = formStyle.style.propertiesSource;

  const toColor = (name: keyof FormContainerStyle): Color => {
    const value = properties[name];
    const valueSource = propertiesSource[name] ?? 'value';

    const alphaName = `alpha-${name}` as keyof FormContainerStyle;
    const alpha = parseFloat(properties[alphaName] ?? '1');
    const alphaSource = propertiesSource[alphaName] ?? 'value';

    return value ? { value, valueSource, alpha, alphaSource } : undefined;
  };

  const boxShadow = properties.shd ? parseBoxShadow(properties.shd) : undefined;

  const shadowToColor = (shadow: BoxShadow) => {
    const value = shadow.hexColor;
    const valueSource = 'value';

    const alpha = shadow?.a ?? 1;
    const alphaSource = 'value';

    return value ? { value, valueSource, alpha, alphaSource } : undefined;
  };

  const styleMap: FormStyle = {
    colors: {
      formBackground: toColor('bg'),
      borderColor: toColor('brd'),
      shadowColor: boxShadow ? shadowToColor(boxShadow) : undefined,
    },
    numbers: {
      horizontalPadding: undefined,
      verticalPadding: undefined,
      rowSpacing: undefined,
      columnSpacing: undefined,

      borderWidth: properties.brw ? parseInt(properties.brw, 10) : undefined,
      borderRadius: extractBorderRadius(properties.rd),

      shadowAngle: boxShadow?.angle && Math.round(boxShadow?.angle),
      shadowDistance: boxShadow?.distance && Math.round(boxShadow?.distance),
      shadowSize:
        boxShadow?.spreadRadius && Math.round(boxShadow?.spreadRadius),
      shadowBlur: boxShadow?.blurRadius && Math.round(boxShadow?.blurRadius),
    },
    booleans: {
      enableShadow: properties['boxShadowToggleOn-shd']
        ? Boolean(properties['boxShadowToggleOn-shd'])
        : undefined,
    },
  };

  const remappedStyle = convertStyles(styleMap, fontMap);

  debug(TAG, 'remapped form style', remappedStyle);

  return remappedStyle;
}
